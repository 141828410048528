<template>
    <section>
        <titlebar :title-stack="titleStack"></titlebar>
        <div class="box">
            <b-loading :active.sync="isLoading"></b-loading>
            <div class="columns">
                <div class="column is-5">
                    <b-field label="Unidade">
                        <b-select v-model="unidadeId" @input="loadChartData"
                                  expanded>
                            <option :value="null">Todas</option>
                            <option v-for="(item, index) in unidades"
                                    v-bind:key="index"
                                    :value="item.id">
                                {{item.nome}}
                            </option>
                        </b-select>
                    </b-field>
                    <div class="field">
                        <b-switch v-model="contabilizarEntreguesAtrasados" @input="loadChartData">
                            Contabilizar exames sem material entregue como atrasados
                        </b-switch>
                    </div>
                </div>
                <div class="column is-3">
                    <b-field label="Visualizar">
                        <b-select v-model="dias" @input="loadChartData">
                            <option value="1">últimas 24 horas</option>
                            <option value="2">últimos 2 dias</option>
                            <option value="3">últimos 3 dias</option>
                            <option value="5">últimos 5 dias</option>
                            <option value="7">últimos 7 dias</option>
                            <option value="10">últimos 10 dias</option>
                            <option value="15">últimos 15 dias</option>
                            <option value="20">últimos 20 dias</option>
                            <option value="30">últimos 30 dias</option>
                            <option value="45">últimos 45 dias</option>
                            <option value="60">últimos 60 dias</option>
                            <option value="90">últimos 90 dias</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-2">
                    <b-field label="Urgentes">
                        <b-select v-model="urgencia" @input="loadChartData">
                            <option :value="null">Todos</option>
                            <option value="rotina">Rotina</option>
                            <option value="urgentes">Urgentes</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-2">
                    <b-field label="Atualizar">
                        <b-select v-model="intervalo" @input="setTimer">
                            <option value="0">Nunca</option>
                            <option value="1">Cada minuto</option>
                            <option value="2">Cada 2 minutos</option>
                            <option value="5">Cada 5 minutos</option>
                            <option value="10">Cada 10 minutos</option>
                            <option value="20">Cada 20 minutos</option>
                            <option value="30">Cada 30 minutos</option>
                        </b-select>
                    </b-field>
                </div>
                
            </div>
        </div>
        <b-notification  v-show="!isLoading && chartData.length == 0">Não foram encontrados exames com o filtro selecionado</b-notification>
        <div class="columns is-multiline">
            <div :class="getClass()" v-for="item, index in chartData" :key="index">
                <doughnut-chart :chart-data="groupChartData(item)" :height="120" @on-selecionarSetorStatus="listarExames" :chart-id="item.setorId"></doughnut-chart>
                <div class="panel">
                    <p class="panel-heading">{{ item.setor }}</p>
                    <p class="panel-block">
                        <b-button @click="btnListarExames(item)" v-show="item.atrasados > 0" type="is-danger" title="Quantidade de exames atrasados">{{ item.atrasados }} /</b-button>
                        <span>{{ item.total }} exame</span>
                        <span v-if="item.total > 1">s</span>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import titlebar from '@/components/titlebar.vue'
    import { mapState } from 'vuex'
    import DoughnutChart from '@/components/Charts/DoughnutChart'

    export default {
        data() {
            return {
                dias: 1,
                contabilizarEntreguesAtrasados: false,
                unidadeId: null,
                chartData: [],
                intervalo: 0,
                timer: null,
                urgencia: null,
                isLoading: true
            }
        },
        components: {
            titlebar,
            DoughnutChart
        },
        computed: {
            titleStack() { 
                return [
                    'Análise',
                    'Progresso por setor'
                ]
            },
            ...mapState([
                'config',
                'unidades'
            ])
        },
        mounted() {
            this.loadChartData()
        },
        beforeDestroy() {
            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },
        methods: {
            getClass(){
                
                if(window.innerWidth > 1216 ){
                    return 'column is-2';
                }else if ( window.innerWidth > 1024 && window.innerWidth < 1215  ){
                    return 'column is-3';
                }else if ( window.innerWidth < 1023){
                    return 'column is-4';
                }
                
            },
            loadChartData() {
                this.isLoading = true
                this.chartData = []
                let url = '/api/analitico/progressoSetor?dias=' + this.dias + '&contabilizarEntreguesAtrasados=' + this.contabilizarEntreguesAtrasados
                if (this.unidadeId != null)
                    url += '&unidadeId=' + this.unidadeId
                if (this.urgencia != null)
                    url += '&urgencia=' + this.urgencia
                this.$http.get(url)
                    .then(res => {
                        this.chartData = res.data
                        this.isLoading = false
                    });
            },
            groupChartData(item) {
                return [
                    {
                        'status': 'Aguardando material',
                        'cor': '#c9c9c9',
                        'qtde': item.aguardandoMaterial
                    },
                    {
                        'status': 'Material entregue',
                        'cor': '#ff9900',
                        'qtde': item.materialEntregue
                    },
                    {
                        'status': 'Bancada emitida',
                        'cor': '#18d8f2',
                        'qtde': item.bancadaEmitida
                    },
                    {
                        'status': 'Digitados',
                        'cor': '#0066ff',
                        'qtde': item.digitados
                    },
                    {
                        'status': 'Assinados',
                        'cor': '#339966',
                        'qtde': item.assinados
                    },
                    {
                        'status': 'Atrasados',
                        'cor': '#cc0000',
                        'qtde': item.atrasados
                    },
                ]
            },
            setTimer() {
                if (this.timer != null) {
                    clearInterval(this.timer)
                }
                if (this.intervalo > 0) {
                    this.timer = setInterval(this.loadChartData, this.intervalo * 60 * 1000)
                }
            },
            listarExames(data) {
                this.$router.push({ name: 'procuradeexames', query: { setorid: data.chartId, status: data.label, dias: this.dias, unidadeId: this.unidadeId, contabilizarEntreguesAtrasados: this.contabilizarEntreguesAtrasados}})
            },
            btnListarExames(dataItem) {
                const statusAtrasado = dataItem.atrasados > 0 ? 'Atrasados' : null;
                this.$router.push({ name: 'procuradeexames', query: { setorid: dataItem.setorId, status: statusAtrasado, dias: this.dias, unidadeId: this.unidadeId, contabilizarEntreguesAtrasados: this.contabilizarEntreguesAtrasados}})
            }
        }
    }

</script>

    <!--
        NOVA AGENDA
    -->
<template>
    <div class="column is-12" >
        <div v-for="(evento,index) in eventos" :key="index"
            :class="tipoEventoStyle(evento)"  >
            <div class="column is-2 has-text-centered">
                <b class="is-size-6 is-paddingless" >{{evento.hour}}</b>
                <b-icon v-if="evento.tipoAgendamentoCor"
                        :style="tipoAgendamentoStyle(evento)"    
                        size="is-small"                                            
                        icon="square-rounded"></b-icon>
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && !isConfirmado(evento)" :title="$t('AGENDA.PACIENTENAOCONFIRMADO')" class="has-text-danger">!</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isConfirmado(evento)" :title="$t('AGENDA.PACIENTECONFIRMADO')" class="has-text-success">&radic;</b> 
                <b v-if="!isBloqueio(evento) && isAgendado(evento) && isEncaixe(evento)" :title="$t('AGENDA.PACIENTEENCAIXE')" class="has-text-black">&crarr;</b> 
                
                                       
            </div>
            <div class="column is-10" style="margin-left: 5px; min-height: 40px;">

                <span  v-show="habilitarBotoes(evento)"  class="is-pulled-right">

                    <b-button class="is-pulled-right" position="is-bottom-left"  v-show="!isBloqueio(evento) && !isAgendado(evento)"   :title="$t('AGENDA.BLOQUEARHORARIO')" 
                        icon-left="cancel" type="is-black"   @click="bloquearHorario(evento)" ></b-button>                                                          
                    <b-button class="is-pulled-right" position="is-bottom-left" v-show="isBloqueio(evento) "  
                        :title="$t('AGENDA.DESBLOQUEARHORARIO')" icon-left="lock-open"  @click="desbloquearHorario(evento.id) "   ></b-button> 

                    <b-checkbox v-if="(exibirCheckBox)" :native-value="true" v-model="evento.selecionado" :disabled="evento.bloquearAgenda"
                         class="mt-2 mx-0" v-show="!isBloqueio(evento) && !isAgendado(evento)"  @input="verificarAgenda(evento)">
                    </b-checkbox>
                    <b-button v-else class="is-pulled-right" position="is-bottom-left" v-show="!isBloqueio(evento) && !isAgendado(evento)" :title="$t('AGENDA.INCLUIRPACIENTE')"  
                        icon-left="account-plus" @click="incluirNovoHorarioDia(evento, evento.dataHora)"></b-button>                                

                         
                </span>
                             
                <p  class="is-size-5" >{{tratarNomePaciente(evento)}}</p>
                              
            </div>
        </div>
    </div>
</template>


<script>

import moment from 'moment';

export default {
    props: {
        eventos: Array,
        funcionarioId: Number,   
        itemId: Number,   
        agendamentoId: Number,     
        agendaWorklist: {
            type: Boolean,
            default: false
        },
        exibirCheckBox: Boolean             
    },
    methods: {

        habilitarBotoes(evento){
            return this.$moment(evento.dataHora).toDate() > new Date();                                
        },        

        tipoAgendamentoStyle(evento) {
            return 'color: #' + evento.tipoAgendamentoCor + '!important'
        },
        tipoEventoStyle(evento) {
            let classes = 'box columns is-mobile is-gapless is-paddingless';
            
            if(this.isBloqueio(evento))
                classes += ' has-text-light has-background-dark';

            if(this.isAgendado(evento))
                classes += ' has-background-info has-text-light';
            
            if(evento.descricaoExame)
                classes += ' has-background-success';
            
            return classes;                

        },    
  
        tratarNomePaciente(evento){

            if(evento.pacienteNome){
                return evento.pacienteNome.toLowerCase();
            }else if(evento.observacao == 'Reservado') {
                return this.$t('AGENDA.HORARIORESERVADO');
            }else if(evento.id>0 && !evento.pacienteNome){
                return this.$t('AGENDA.HORARIOBLOQUEADO');
            }else if (evento.descricaoExame) {
                return evento.descricaoExame;  
            }
            
        },

        
        isBloqueio(evento){              
            
            return (evento.id && (!evento.pacienteNome || !evento.observacao == 'Reservado') && evento.observacao=='Bloqueio');                
        }, 
        isAgendado(evento){              
            
            return (evento.pacienteNome || evento.observacao == 'Reservado');                
        },  
        isConfirmado(evento){              
            
            return (evento.pacienteConfirmado);                
        },  
        isEncaixe(evento){              
            
            return (evento.encaixe);                
        },                          
        desbloquearHorario(id){                
            const evento = {id: id};
            this.agendaStatus(evento, 'cancelar');
        },            
                

        verificarAgenda(evento){            
            
            this.$emit("verificarAgenda", evento);
            
        }, 
        bloquearHorario(evento){            
            const bloqueio = {
                data: evento.dataHora,
                observacao: 'Bloqueio',
                idPaciente: 0,
                idTipoAgendamento: evento.tipoAgendamentoId,
                funcionarioId: null,
                paciente: { id: 0 },
                tipoAgendamento: { id: evento.tipoAgendamentoId},
            }
            
            this.$emit("incluirNovoHorarioDia", bloqueio,evento.dataHora,true);
            
        },          
         incluirNovoHorarioDia(evento,dataHora){   
                        

            if(evento.id > 0){

                this.$buefy.dialog.confirm({
                    title: this.$t('AGENDA.ATENCAO'),
                    message: this.$t('AGENDA.CONFIRMAPACIENTE'),
                    type: 'is-info',
                    hasIcon: true,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    onConfirm: () => {       
                        evento.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null                                                                   
                        this.$emit("incluirNovoHorarioDia", evento,dataHora,false);                        
                    },
                                    
                })

            }else{
                evento.funcionarioId = (this.funcionarioId) ? this.funcionarioId : null ;
                evento.itemId = this.itemId;   
                this.$emit("incluirNovoHorarioDia", evento,dataHora,false);
            }


         },         
        async agendaStatus(evento,acao) {
            const params = [];
            if (evento.id) params.push(`id=${evento.id}`);

            params.push(`acao=${acao}`);
            if (evento.itemId) params.push(`itemId=${evento.itemId}`);

            try {
                const url = `/api/agenda/AgendaStatus?${params.join('&')}`;
                const res = await this.$http.get(url);
                this.$emit('loadDataDia')
            } catch (e) {
                console.error(e);
            }

        }                                      
    }
};
</script>